import logo from './logo.svg';
import './App.css';
import { Button, Card } from '@mui/material';
import Home from './Home';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    // Set the background color of the body element to light pink
    document.body.style.backgroundColor = '#FFC0CB'; // Light pink color code
  }, []);


  return (
    <div className="App">
      <Home></Home>
    </div>
  );
}

export default App;
