import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Envelope from './Envelope'; // Assuming Envelope is in a separate file or adjust accordingly

const Home = () => {
  const [code, setCode] = useState('');
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);
  const correctCode = '2412';

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const verifyCode = () => {
    if (code === correctCode) {
      setIsCodeCorrect(true);
    } else {
      alert('Incorrect code. Please try again.');
      setIsCodeCorrect(false);
    }
  };

  return (
    
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // Full viewport height
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px', // Spacing between TextField and Button
        }}
      >
        {!isCodeCorrect && (
        <>
            <TextField
            label="Enter Code"
            variant="outlined"
            value={code}
            onChange={handleCodeChange}
            />
            <Button variant="contained" color="primary" onClick={verifyCode}>
            Submit
            </Button>
        </>
        )}
      </Box>
      {isCodeCorrect && (
        <Box
          sx={{
            marginTop: '20px', // Add some space above the envelope
          }}
        >
          <Envelope />
        </Box>
      )}
    </Box>
  );
};

export default Home;
