import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MailOutlineIcon from '@mui/icons-material/MailOutline'; // Envelope icon
import FavoriteIcon from '@mui/icons-material/Favorite'; // Heart icon


const Envelope = () => {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: 100,
          height: 60,
          backgroundColor: '#f8bbd0', // Light pink background for the envelope
          borderRadius: '10px',
          padding: '10px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          '&:hover > div': {
            maxHeight: '200px', // Allows the message box to expand
            opacity: 1, // Make the message fully visible
          },
        }}
      >
        <MailOutlineIcon sx={{ fontSize: 40, color: '#e57373', position: 'absolute' }} />
        <FavoriteIcon sx={{ fontSize: 24, color: '#f44336', position: 'absolute', top: 0, right: 0 }} />
        <Box
          sx={{
            position: 'absolute',
            bottom: '-10px',
            width: 'calc(100% + 20px)',
            maxHeight: 0, // Initially hide the message box
            overflow: 'hidden',
            backgroundColor: '#ffffff',
            transition: 'max-height 0.5s ease, opacity 0.5s ease',
            opacity: 0, // Make the message initially transparent
            borderRadius: '5px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            padding: '10px',
            textAlign: 'center',
            fontSize: '0.9rem',
          }}
        >
          Razinah... Will you be my valentine?
        </Box>
      </Box>
    );
  };
  


export default Envelope;